
import tpl from './index.tpl';
import './index.scss';
import tplReplace from '../../utils/tplReplace';

export default () => ({
  name: 'SignPage',
  render(data) {
    return tplReplace(tpl(), data);
  },
});
