module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<img src="' +
((__t = (require('./loading.gif'))) == null ? '' : __t) +
'" class="loading-icon" alt="" />';

}
return __p
}