

import tpl from './index.tpl';
import './index.scss';
import tplReplace from '../../utils/tplReplace';
import { getFromStorage, removeStorage ,  pushToStorage , addToStorage} from '../../utils/localStorage';
export default () => ({
  name: 'OfficialSeal',
  render(data) {
    let  legalPersonPhone = getFromStorage('legalPersonPhone');
    let  companyName = getFromStorage('companyName');
    
    return tplReplace(tpl(), { companyName : companyName , legalPersonPhone: legalPersonPhone  });
  },
});
