/*
 * @Description:
 * @Author: xuxin
 * @Date: 2021-12-09 11:07:57
 * @LastEditTime: 2021-12-09 11:32:45
 * @LastEditors: xuxin
 * @FilePath: /webpack-study/src/tpl/footer/index.js
 * @Reference:
 */
import tpl from './index.tpl';
import './index.scss';
import tplReplace from '../../utils/tplReplace';
import { getFromStorage, removeStorage ,  pushToStorage , addToStorage} from '../../utils/localStorage';

export default () => ({
  name: 'Footer',
  tpl : `<li class="item sign-btn"><i class="iconfont icon-sign"></i><span>手写</span></li>`,
  reFresh(data) {
  },
  render(data) {
    let role = getFromStorage('userRole');
    let telUl = `
    <ul class="list J_list" >
    <li class="item add-btn"><i class="iconfont icon-add"></i><span>个人签名</span></li>
    <li class="item date-btn"><i class="iconfont icon-date"></i><span>日期</span></li>
    <ul>
    `
    //<li class="item sign-btn"><i class="iconfont icon-sign"></i><span>手写</span></li>
    if(role == 2){
      telUl = ` 
      <ul class="list J_list" >
      <li class="item add-btn"><i class="iconfont icon-add"></i><span>印章</span></li>
      <li class="item date-btn"><i class="iconfont icon-date"></i><span>日期</span></li>
      <ul>`
    }
    return tplReplace(tpl(), {
      telUl,telUl
    });
  },

  
});
