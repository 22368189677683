// import request from '.';
// /**
//  * 添加手写签字
//  * @param {*} data 
//  * @returns 
//  */
//  export default function  addHandSignature(data) {
//     return request({
//       url: '/v1/addHandSignature',
//       method: 'post',
//       data,
//     });
//   }

import request from '.';

export default function addHandSignature(data) {
  return request({
    url: '/v1/addHandSignature',
    method: 'post',
    data,
  });
}