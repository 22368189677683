
import tpl from './index.tpl';
import './index.scss';
import tplReplace from '../../utils/tplReplace';

export default () => ({
  name: 'Header',
  renderHeader(data) {
  
    return tplReplace(tpl(), data);
  },
});
