
import tpl from './index.tpl';
import './index.scss';
import tplReplace from '../../utils/tplReplace';
import { getFromStorage, removeStorage ,  pushToStorage , addToStorage} from '../../utils/localStorage';

export default () => ({
  name: 'Draw',
  tpl: `
    <li class="sig-item exist-signature" data-id='{{id}}' content='点击使用 ( 请拖拽到指定位置  )'>
        <img src="{{img}}" alt="">
    </li>
  `,
  reFresh(data) {
    let listStr = '';
    data && data.forEach((item) => {
      listStr += tplReplace(this.tpl, item);
    });
    $('.J_sig-list').append(listStr);
  },
  render(data) {
   
    let userRole  = getFromStorage('userRole');
    let listStr = `<li class="sig-item add-signature" content='点击添加签名'>
                    +
                  </li>`;
    let title  =  '手写签名' 
    if(userRole  == 2 ){
      title = '公司印章'
      listStr = `<li class="sig-item add-official-seal" content='点击添加公章'>
      +
    </li>`;
    }
   
    data && data.forEach((item) => {
      listStr += tplReplace(this.tpl, item);
    });
   
    return tplReplace(tpl(), {
      signList: listStr,
      title  : title 
    });
  },
  toggle(flag) {
    const draw = $('.J_draw');
    if (flag) {
      draw.css('transform', `translate3d(0,-${draw.height()}px,0)`);
    } else {
      draw.css('transform', 'translate3d(0,0,0)');
    }
  },
});
