
/* global $, FormData, window */
/* eslint no-use-before-define: ["error", { "classes": false }] */
/* eslint class-methods-use-this: ["error", {"exceptMethods":[ "signDeleteHandler" ]} ] */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true , "allowTernary": true }] */
import Header from '../tpl/header';
import Pdf from '../tpl/pdf';
import Footer from '../tpl/footer';
import Draw from '../tpl/draw';
import DatePicker from '../tpl/datepicker';
import Mask from '../tpl/mask';
import Loading from '../tpl/loading';
import _Float from '../plugins/float';
import { dataURLToFile, getPicFromCustomText } from '../utils/dataURLToFile';
import compoundPdf from '../request/pdf';
import initChapter from '../request/initChapter';
import renderPdf from '../utils/pdfRender';
import sendCode from '../request/sendAuthorizeCode';
import  sendAuthorizeCode from '../request/authorizationCodeVerification'
import addHandSignature from '../request/addHandSignature';
import { getFromStorage, removeStorage, pushToStorage, addToStorage } from '../utils/localStorage';
import eb from '../utils/eventbus';
import 'jquery-confirm/css/jquery-confirm.css';
import 'jquery-confirm/js/jquery-confirm.js';
import { v4 as uuidv4 } from 'uuid';
const container = $('#container');
const header = Header();
const pdf = Pdf();
const footer = Footer();
const draw = Draw();
const datePicker = DatePicker();
const mask = Mask();
const loading = Loading();
let signArr;
let floatArr = [];
let specifiedPos = {}; // 指定签名摆放的位置,默认为x:100 y:100
let addHandSignatureInfo = null;  // 添加手写签名  
let dataTransmissionArr =[]; //签字完成时验签数据
let handSignatureImgId = [];
let handSignatureImg = [];
var filedName = null;
var phone = null;
var userRole = null;
var confirmSigning = false;
var handlePdfPageClickStatus = false;   // 点击屏幕位置状态
var positionPage = [];
// 继承Float类,添加删除回调
class Float extends _Float {
  signDeleteHandler(id) {
    confirmSigning = false;
    floatArr = floatArr.filter((item) => item.id !== id);

    dataTransmissionArr =floatArr.filter((item)=> item.id == id);

    //删除用的签名或公章
    let deletIndex = handSignatureImgId.indexOf(id);
    let dataTransmissionIndex = dataTransmissionArr.indexOf(id);
    floatArr.splice(dataTransmissionIndex, 1);
    handSignatureImgId.splice(deletIndex, 1);
    deletIndex = handSignatureImg.indexOf(id);
    handSignatureImg.splice(deletIndex, 1);
  }
}
// event handler
// 签名列表draw打开/关闭
const drawToggleHandler = (flag) => {
  draw.toggle(flag);
  mask.toggle(flag);
};


// 添加签名
const addSignatureHandler = (e) => {
  if (!handlePdfPageClickStatus) {
    const { clientX, clientY } = e;
    specifiedPos = {
      //x: clientX - parseInt($('.pdfViewer').css('padding-left'), 10),
      //y: clientY + $('.viewerContainer')[0].scrollTop - $('.header').height() - parseInt($('.pdfViewer').css('padding-top'), 10),
      x: 288,
      y: 455 + $('.viewerContainer')[0].scrollTop,
    };
  }
  const tar = $(e.currentTarget);
  const signItem = signArr.find((item) => item.id === tar.attr('data-id'));
  
  if (!signItem) return;
  confirmSigning = true;

  //添加完时， 显示每次的时间戳等数据
  const useId =   uuidv4();
  let info  =  { 
    time  :Date.now(),
    useId : useId,
    imId  :signItem['id'],
  }
  dataTransmissionArr.push(info);
  //console.log(dataTransmissionArr);

  handSignatureImgId.push(tar.attr('data-id'));
  // console.log(handSignatureImgId);
  handSignatureImg.push();
  floatArr.push(new Float({ ...signItem, ...specifiedPos }));
  handSignatureImg.push(signItem.img);
  addHandSignatureInfo = signItem;
  drawToggleHandler();
  handlePdfPageClickStatus = false
};
// 显示签名页面(手写)
const showSignPage = () => {
  // eslint-disable-next-line
  window && window.history.pushState({}, null, '/sign');
  eb.emit('showSignPage');
  // signPage.addClass('show');
};
// 直接添加公章
const showOfficialSealPage = () => {
  console.log(`点击添加`)
  window && window.history.pushState({}, null, '/officialSeal');
  eb.emit('showOfficialSealPage');
}
// 弹出时间选择器
const showDatePicker = () => {
  mask.toggle(true);
  datePicker.show();
};
const closedPage = () => {
  console.log(`=== 点击【返回】===`);
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  let str = { "success": 0, "url": '' }
  str = JSON.stringify(str)
  if (isAndroid) {
    window.xinfushenghuo.closePage();
  }
  if (isIOS) {
    window.webkit.messageHandlers.closePage.postMessage(str);
  }
};
// 隐藏时间选择器
const datepickerCloseHandler = () => {
  mask.toggle(false);
  datePicker.hide();
};
// 添加时间签名
const addTimeHandler = (e) => {
  const tar = $(e.currentTarget);
  const { clientX, clientY } = e;
  specifiedPos = {
    x: 288,
    y: 455 + $('.viewerContainer')[0].scrollTop,
    //x: clientX - parseInt($('.pdfViewer').css('padding-left'), 10),
    //y: clientY + $('.viewerContainer')[0].scrollTop - $('.header').height() - parseInt($('.pdfViewer').css('padding-top'), 10),
  };
  let floatTimeInfo = {
    id: (`${Math.random()}`).slice(2, 5) + (`${Date.now()}`).slice(-5, -1),
    img: getPicFromCustomText(tar.text()),
    canScale: false,
  };
  floatArr.push(new Float({ ...floatTimeInfo, ...specifiedPos }));
  datepickerCloseHandler();

};
// 完成签署
const confirmHandler = async () => {
  //公司角色
  //本次签署涉及到使用公章，请发送信息给法人进行授权
  if (userRole == 1) {
    if (!confirmSigning) {
      $.alert({
        title: '温馨提示',
        content:'请使用一个签名',
        icon: 'fa fa-info-circle',
        type: 'blue',
        buttons: {
          ok: {
            text: '确定',
            btnClass: 'btn-blue',
            action: async function () {
              return;
            }
          },
          cancel: {
            text: '取消'
          }
        }
      });
      return;
    }
    $.alert({
      title: '温馨提示',
      content: '发送验证码',
      icon: 'fa fa-info-circle',
      type: 'blue',
      buttons: {
        ok: {
          text: '确定',
          btnClass: 'btn-blue',
          action: async function () {
            const ret = await sendCode({"phoneNum":phone,type:2, userRole:1});
            $.alert({
              title: '温馨提示',
              content: '<input type="text"  value ="" id="personCode" placeholder="请输入验证码"  />',
              icon: 'fa fa-info-circle',
              type: 'blue',
              buttons: {
                ok: {
                  text: '确认签署',
                  btnClass: 'btn-blue',
                  action: async function () {
                    let code  = $('#personCode').val();
                    let data  = { phone ,code , userRole };
                    if(code == '' ) {
                        alert('验证码不能为空');
                        return ;
                    }
                    let ret  = await sendAuthorizeCode(data);
                    //console.log(ret.code)
                    if(ret.code  == 200 ) {
                      await confirmSignature();
                    }else{
                      alert('验证码验证失败');
                    }
                   
                    return;
                  }
                },
                cancel: {
                  text: '取消'
                }
              }
            });


          }
        },
        cancel: {
          text: '取消'
        }
      }
    });
  }
  if (userRole == 2) {
    $.alert({
      title: '温馨提示',
      content: '本次签署涉及到使用公章，请发送信息给法人进行授权',
      icon: 'fa fa-info-circle',
      type: 'blue',
      buttons: {
        ok: {
          text: '确定发送',
          btnClass: 'btn-blue',
          action: async function () {
           const ret = await sendCode({"phoneNum":phone,type:2, userRole:2});
            $.alert({
              title: '温馨提示',
              content: '<input type="text"  value ="" id="code" placeholder="请输入授权码"  />',
              icon: 'fa fa-info-circle',
              type: 'blue',
              buttons: {
                ok: {
                  text: '确定',
                  btnClass: 'btn-blue',
                  action: async function () {
                    let code  = $('#code').val();
                    let data  = { phone ,code,userRole };
                    if(code == '' ) {
                        alert('授权码不能为空');
                        return ;
                    }
                    let ret  = await sendAuthorizeCode(data);
                    if(ret.code  == 200 ) {
                      await confirmSignature();
                    }else{
                      alert('验证码验证失败');
                    }
                    //await confirmSignature();
                   // console.log(ret);
                    // if(ret.code == 200 ) {
                    //   
                    //   return ;
                    // }else{
                    //   alert('授权码不正确');
                    //   return ;
                    // }
                    //await confirmSignature();
                    return;
                  }
                },
                cancel: {
                  text: '取消'
                }
              }
            });
            return;
          }
        },
        cancel: {
          text: '取消'
        }
      }
    });
  }

 
  return;
};
//添加手写入库
const addHandwritten = async () => {
  addHandSignatureInfo['phone'] = phone;
  addHandSignatureInfo['img'] = addHandSignatureInfo['img'];
  await addHandSignature(addHandSignatureInfo);
}
/**
 * 369258huifa@
 * 确认签署
 */
const confirmSignature = async () => {
  let test = $(".confirm-btn").text();
  $(".confirm-btn").attr('disabled', 'disabled');
  var count = 30;
  var countdown = setInterval(CountDown, 1000);
  function CountDown() {
    $(".confirm-btn").attr("disabled", true);
    $(".confirm-btn").text("请等待" + count + "S后再提交");
    if (count == 0) {
      $.alert({
        title: '温馨提示',
        content: '我们正在努力处理，请稍等……',
        icon: 'fa fa-info-circle',
        type: 'blue',

      });
      clearInterval(countdown);
    }
    count--;
  }
  const getFile = () => {
    const len = floatArr.length;
    let idx = 0;
    return new Promise((resolve) => {
      floatArr.forEach(async (item) => {
        // eslint-disable-next-line
        item.file = await dataURLToFile(item.img);
        // eslint-disable-next-line
        idx++;
        if (idx === len) {
          resolve();
        }
      });
    });
  };
  await getFile();
  const formdata = new FormData();
  const configArr = [];
  // eslint-disable-next-line
  for (let i = 0, len = floatArr.length; i < len; i++) {
    formdata.append('imgs', floatArr[i].file);
    positionPage.push(+floatArr[i].page + 1);
    configArr.push({
      x: floatArr[i].x,
      y: floatArr[i].y,
      width: floatArr[i].width,
      height: floatArr[i].height,
      page: floatArr[i].page,
    });
  }
  formdata.append('config', JSON.stringify(configArr));
  formdata.append('filedName', filedName);
  formdata.append('phone', phone);
  formdata.append('positionPage', JSON.stringify(positionPage));
  formdata.append('handSignatureImgId', JSON.stringify(handSignatureImgId));
  formdata.append('dataTransmissionArr',  JSON.stringify(dataTransmissionArr))
  formdata.append('userRole', userRole);
  const res = await compoundPdf(formdata);
  $('.J_footer').hide();
  $('.pdf-preview-wrap').css('padding-bottom', 0);
  renderPdf('#preview', `/api/pdf/getpdf?filename=${res.filename}`).on('complete', () => { });
  //通知手机端关闭
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  console.log(u);
  let str = { "success": 0, "url": res.ossInfoUrl }
  str = JSON.stringify(str)
  if (isAndroid) {
    window.xinfushenghuo.confirmSign(str);
  }
  if (isIOS) {
    window.webkit.messageHandlers.confirmSign.postMessage(str);
  }
}


// pdf点击事件: 记录点击位置 => 打开签名列表draw
const handlePdfPageClick = (e) => {
  const { clientX, clientY } = e;
  specifiedPos = {
    x: clientX - parseInt($('.pdfViewer').css('padding-left'), 10),
    y: clientY + $('.viewerContainer')[0].scrollTop - $('.header').height() - parseInt($('.pdfViewer').css('padding-top'), 10),
  };
  handlePdfPageClickStatus = true;
  drawToggleHandler(true);
};
// 遮罩层点击
const maskClickHandler = () => {
  drawToggleHandler();
  datepickerCloseHandler();
};
const render = () => new Promise((resolve) => {
  let domStr = '';
  domStr += header.renderHeader();
  domStr += pdf.render();
  domStr += footer.render();
  domStr += draw.render(signArr);
  domStr += datePicker.render();
  domStr += mask.render();
  domStr += loading.render();
  container.append(domStr);
  resolve();
});
const bindEvent = () => {
  // 首页footer点击事件
  $('.J_footer').on('click', '.add-btn', drawToggleHandler.bind(null, true)) // footer按钮点击事件
    .on('click', '.confirm-btn', confirmHandler)
    .on('click', '.sign-btn', showSignPage)
    .on('click', '.date-btn', showDatePicker);
  // draw事件代理
  $('.J_draw').on('click', '.close-btn', drawToggleHandler.bind(this, false)) // draw关闭按钮点击事件
    .on('click', '.exist-signature', addSignatureHandler)
    .on('click', '.add-signature', drawToggleHandler, showSignPage)
    .on('click', '.add-official-seal', drawToggleHandler, showOfficialSealPage);
  // mask遮罩层事件
  $('.J_mask').on('click', maskClickHandler);
  // $('#preview').on('del-sign', signDeleteHandler);
  // 时间选择器点击事件
  $('.J_datepicker-wrap').on('click', '.cancel', datepickerCloseHandler)
    .on('click', '.candidate-item', addTimeHandler);
  // pdf点击事件
  $('.pdfViewer').on('click', handlePdfPageClick);
  //返回关闭
  eb.on('freshDraw', () => {
    signArr = getFromStorage('signArr');
    console.log(signArr);
    draw.reFresh(signArr);
  });
  $("#closedPage").on('click', closedPage);

};
async function init() {
  removeStorage('signArr')  //先清空所有保存后的签名数据 
  removeStorage('userRole')
  removeStorage('legalPersonPhone')
  removeStorage('companyName')
  removeStorage('phone');
  //获取当前用户的私钥。
  var url = window.location.href;
  //console.log(url);
  let urlStr = url.split('?')[1]
  // 创建空对象存储参数
  var paramObj = {};
  // 再通过 & 将每一个参数单独分割出来
  let paramsArr = urlStr.split('&')
  for (let i = 0, len = paramsArr.length; i < len; i++) {
    // 再通过 = 将每一个参数分割为 key:value 的形式
    let arr = paramsArr[i].split('=')
    paramObj[arr[0]] = arr[1];
  }
  filedName = paramObj['name']
  userRole = paramObj['userRole'];
  phone = paramObj['phone']
  addToStorage('userRole', userRole);
  addToStorage('phone', phone);
  if (+userRole == 2) {
    const ret = await initChapter({ "phoneNum": phone, type: userRole });

      if(ret['code'] == 200){  
      const legalPersonPhone = ret['data']['data']['legalPersonPhone'];
      const companyName = ret['data']['data']['companyName'];
      addToStorage('legalPersonPhone', legalPersonPhone);
      addToStorage('companyName', companyName)
      const obj = eval(ret['data']['data']['img']);
      for (let i = 0; i < obj.length; i++) {
        let arr = getFromStorage('signArr');
        arr ? pushToStorage('signArr', obj[i]) : addToStorage('signArr', [obj[i]]);
      }

    }
  }
  return new Promise((resolve) => {
    // 保存所签名的文件，
    signArr = getFromStorage('signArr');
    render().then(() => {
      renderPdf('#preview', `/api/pdf/default?filedUrl=${paramObj['filedUrl']}&name=${filedName}`).on('complete', () => {
        bindEvent();
        resolve();
      });
    });
  });
}
export default init;
