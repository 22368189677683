/**
 * 添加公章
 */
import Header from '../tpl/header';
import OfficialsSeal from '../tpl/officialSeal';
import eb from '../utils/eventbus';
import sendCode from '../request/sendAuthorizeCode';
import createChapter from '../request/createChapter';
import { getFromStorage, removeStorage ,  pushToStorage , addToStorage} from '../utils/localStorage';
const officialSeal = $('#official-page');
const header = Header();
const officialSealSign = OfficialsSeal();

let   chapterContent = null;
let chapterName = "财务专用章"
// 渲染
const renderOfficialSealPage = () => new Promise((resolve) => {
  let domStr = '';
  domStr += header.renderHeader();
  domStr += officialSealSign.render();
  officialSeal.append(domStr);
  resolve();
});
const handleBack = () => {
  window.history.go(-1);
};
const sendAuthorizeCode = async () => {
  let phone = getFromStorage('phone');
  const ret = await sendCode({"phoneNum":phone,type:1,userRole:2});
  console.log(ret);
  $("#sendCode").prop("disabled", true);
  let pswtime = 30
  const pswtimeId = setInterval(() => {
    pswtime--
    if (pswtime <= 0) {
      $("#sendCode").prop("disabled", false);
      $("#sendCode").text(`向法人发送确授权码`);
      clearInterval(pswtimeId);
      return ;
    }
    $("#sendCode").text(`${pswtime}秒重新发送`);
  }, 1000);
}
const bindSignEvent = () => {
  $('#official-page .header').on('click', '.back-func', handleBack);
  $("#official-page .confirm-btn").on('click', sendAuthorizeCode);
  $("#official-page [name=radioVal]").on('change',  radiochange);
  $("#official-page .submit-btn").on('click',submitCreateChapter);
};
async function  submitCreateChapter (){
  let phone  = getFromStorage('phone');
  let code   = $('#inputCode').val();
  let img  =  chapterContent ;
  let data  = {phone,code,img ,chapterName}
  let res  = await createChapter(data);
  console.log(res.code);
  if(res.code == 7001){
     alert (`验证码不正确`);
     return ;
  }
  if(res.code == 200 ){
    console.log(`创建`); 
    let arr = getFromStorage('signArr');
    arr ? pushToStorage('signArr', {id:res.data.id, img:chapterContent}) : addToStorage('signArr', [{id:res.data.id, img:chapterContent}]);
    $('.J_draw').find('.exist-signature').remove();
    eb.emit('freshDraw');
    window.history.go(-1);
    return ;
  }
  if(res.code == 70015) {
    alert (`创建失败`);
    return ;
  }
}
//选择公章类型
function radiochange() {
  chapterName = this.value
  clearCanvas();
  let companyName = getFromStorage('companyName')
  chapter(this.value, companyName,(data)=>{
    $("#img").attr("src",data);
    chapterContent = data;
  })
}

function init() {
  renderOfficialSealPage().then(() => {
    bindSignEvent();
    eb.on('showOfficialSealPage', () => {
      officialSeal.addClass('show');
    });
    window.addEventListener('popstate', () => {
      officialSeal.removeClass('show');
    });
    clearCanvas()

    let companyName = getFromStorage('companyName')
    console.log(companyName); 
    if(companyName){
      chapter("财务专用章", companyName,(data)=>{
        $("#img").attr("src",data)
        chapterContent = data;
      })
    }
  })
}

 function clearCanvas () {
  //获取canvas的ID
 
  var canvas = document.getElementById("chapterCanvas");
  canvas.style.display = 'none'
  const ctx = canvas.getContext("2d");
  ctx.fillStyle = "transparent" //背景透明
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.clearRect(0, 0, canvas.width, canvas.height);  
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  //清除对应的坐标和大小
  //创建一个填充颜色的矩形
  // ctx.fillRect(10, 10, 10, 10)
  // ctx.clearRect(100, 100, 250, 250);
}

let chapter = (text, companyName , callbck) => {
  let canvas  = document.getElementById("chapterCanvas");
  let context = canvas.getContext("2d"); 
  
  // 绘制印章边框
  let width = canvas.width / 2;
  let height = canvas.height / 2;
  context.lineWidth = 5;
  context.strokeStyle = "#f00";
  context.beginPath();
  context.arc(width, height, 90, 0, Math.PI * 2); //宽、高、半径
  context.stroke();
  //画五角星
  create5star(context, width, height, 25, "#f00", 0);
  // 绘制印章名称
  context.font = "18px 宋体";
  context.textBaseline = "middle"; //设置文本的垂直对齐方式
  context.textAlign = "center"; //设置文本的水平对对齐方式
  context.lineWidth = 1;
  context.strokeStyle = "#f00";
  context.strokeText(text, width, height + 60);
  //绘制印章单位
  context.translate(width, height); // 平移到此位置,
  context.font = "23px 宋体";
  let count = companyName.length; // 字数
  let angle = (4 * Math.PI) / (3 * (count - 1)); // 字间角度
  let chars = companyName.split("");
  let c;
  for (let i = 0; i < count; i++) {
    c = chars[i]; // 需要绘制的字符
    if (i == 0) {
      context.rotate((5 * Math.PI) / 6);
    } else {
      context.rotate(angle);
    }

    context.save();
    context.translate(70, 0); // 平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
    context.rotate(Math.PI / 2); // 旋转90度,让字平行于x轴
    context.strokeText(c, 0, 0); // 此点为字的中心点
    context.restore();
  }
  let img = canvas.toDataURL('image/png');
  callbck(img)
  //绘制五角星
  function create5star(context, sx, sy, radius, color, rotato) {
    context.save();
    context.fillStyle = color;
    context.translate(sx, sy); //移动坐标原点
    context.rotate(Math.PI + rotato); //旋转
    context.beginPath(); //创建路径
    // let x = Math.sin(0);
    // let y = Math.cos(0);
    let dig = (Math.PI / 5) * 4;
    for (let i = 0; i < 5; i++) {
      //画五角星的五条边
      let x = Math.sin(i * dig);
      let y = Math.cos(i * dig);
      context.lineTo(x * radius, y * radius);
    }
    context.closePath();
    context.stroke();
    context.fill();
    context.restore();
  }
 /*   //清除画布
   function clearCanvas() {
    var c = document.getElementById("canvas");
    var cxt = c.getContext("2d");
    cxt.clearRect(0, 0, c.width, c.height);
  } */
};



export default init;