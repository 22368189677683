
// import $ from 'jquery';
/* global $ */
import tpl from './index.tpl';
import './index.scss';
import tplReplace from '../../utils/tplReplace';

export default () => ({
  name: 'Mask',
  render(data) {
    return tplReplace(tpl(), data);
  },
  toggle(flag) {
    if (flag) {
      $('.J_mask').fadeIn();
    } else {
      $('.J_mask').fadeOut();
    }
  },
});
